import * as api from '../modules/api';

/**
 * Used for getting settings from the API
 * @param {string} name list_name.item for easy lookups
 * @param {string} key the name of the key you wish to grab (default: value)
 * @returns The value of the setting you requested
 */
export async function getSetting(name, key = 'value') {
    try {
        if (!name) {
            throw new Error('No Name for setting provided');
        }
        const [listName, item] = name?.split('.');
        const response = await api.getSettings();
        return response?.body?.settings?.find((setting) => setting.list_name === listName && setting.item === item)?.[
            key
        ];
    } catch (e) {
        console.error('[API][SETTINGS] Error ', e);
        throw e;
    }
}

/**
 * Utility function to check if a string is a valid URL or not
 * @param {string} url string to check
 * @param {boolean} logErrors Whether to log errors to console or not (default: false)
 * @returns {boolean}
 */
export function isValidUrl(url, logErrors = false) {
    try {
        new URL(url);
        return true;
    } catch (e) {
        if (logErrors) {
            console.error(e);
        }
        return false;
    }
}
