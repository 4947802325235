import AimModal from "../../../../shared/AimModal/AimModal";
import LoadingBox from "../../../../shared/LoadingBox/LoadingBox";
import TableButton from "../../../../shared/TableButton/TableButton";

import "./clear_tables_modal.scss";

export default function ClearTablesModal(props) {
    return (
      <AimModal show={props.show}>
        <div id="clear-tables-modal-header">
          <span>Reset AIM Data</span>
        </div>
        <div id="clear-tables-modal-body">
          <p>You're about to reset the AIM bubble data. This action cannot be undone. Are you sure you want to continue?</p>
        </div>
        <div id="clear-tables-modal-footer">
          <TableButton icon="fa-check-circle" text="Reset Bubble Data" onClick={props.onCommit}/>
          <TableButton icon="fa-times-circle" text="Cancel" onClick={props.onCancel}/>
        </div>
        {props.isLoading && <LoadingBox subMessage="Resetting AIM bubble data this will take a few minutes ..." />}
      </AimModal>
    )
}
