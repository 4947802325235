import { useEffect, useState, useCallback } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import TableButton from "../TableButton/TableButton";
import PromoteModal from "./PromoteModal/PromoteModal";
import DemoteModal from "./DemoteModal/DemoteModal";
import AlertModal from "./AlertModal/AlertModal";
import AssignCaaNumberModal from "./AssignCaaNumberModal/AssignCaaNumberModal";
import FilePickerModal from "./FilePickerModal/FilePickerModal";
import ReviewerFeedbackModal from "./ReviewerFeedbackModal/ReviewerFeedbackModal";
import CommitModal from "./CommitModal/CommitModal";
import DeleteModal from "./DeleteModal/DeleteModal";
import RestoreModal from "./RestoreModal/RestoreModal";
import HideColumnsButton from "./HidColumnsButton/HideColumnsButton";
import FreezeColumnsButton from "./FreezeColumnsButton/FreezeColumnsButton";
import DatePickerCell from "../DatePickerCell/DatePickerCell";
import FilePickerModalEditor from "../FilePickerModalEditor/FilePickerModalEditor";
import SelectPageSize from "./SelectPageSize/SelectPageSize";
import StatusCellRenderer from "./StatusCellRenderer/StatusCellRenderer";
import FeedbackCellRenderer from "./FeedbackCellRenderer/FeedbackCellRenderer";
import DefaultCellRenderer from "./DefaultCellRenderer/DefaultCellRenderer";
import FileUploadRenderer from "../FileUploadRenderer/FileUploadRenderer";
import BulkEditModal from "./BulkEditModal/BulkEditModal";
import * as api from "../../../modules/api";
import { getSetting } from '../../../modules/util';

// Used while fetching settings and to render link
import ReactDOMServer from 'react-dom/server';
import LoadingBox from "../LoadingBox/LoadingBox";

import "./record_table.scss";
/*
  props:
    canAddRecords           boolean
    addRecord               function
    canDeleteRecords        boolean
    canCommitRecords        boolean
    canPromoteRecords       boolean
    canEditPromotedRecords  boolean
    canDemoteRecords        boolean
    canEditDemotedRecords   boolean
    canBulkEditRecords      boolean
    canAssignCaaNumber      boolean
    modelDefs               Model object
    tab                     string
    isAccount               boolean
    setShowFlyOut           function
    attachments             array<objects>
*/
export default function RecordTable(props) {
  const [gridApi, setGridApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const [frozenColumnIds, setFrozenColumnIds] = useState([]);
  const [hiddenColumnIds, setHiddenColumnIds] = useState([]);
  const [pageSize, setPageSize] = useState(25);

  // modals
  const [showBulkEditModal, setShowBulkEditModal] = useState(false);
  const [showPromoteModal, setShowPromoteModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDemoteModal, setShowDemoteModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showCommitModal, setShowCommitModal] = useState(false);
  const [showFilePickerModal, setShowFilePickerModal] = useState(false);
  const [showAssignCaaNumberModal, setShowAssignCaaNumberModal] = useState(false);
  const [casBaseUrl, setCasBaseUrl] = useState(null);
 
  const [filePickerModalOptions, setFilePickerModalOptions] = useState(null);

  const [feedbackToView, setFeedbackToView] = useState('');
  const [alertText, setAlertText] = useState('');

  // Flag to trigger picklist extension
  const [contactUpdate, setContactUpdate] = useState(null);

  const allColumns = props.modelDefs.getAllDefs().filter(def => props.isReviewer ? def.isReviewerViewable(props.tab) : def.isAdvisorViewable(props.tab));
  const pathNameMap = new Map(props.modelDefs.getAllDefs().map(col => ([col.path, col.label])));

  const CAA_NUMBER_PATH = "/agreement/Client Agreement & Billing Details/caa_number";
  const CONTACT_FIRST_NAME_PATH = "/client/Contact/first_name";
  const CONTACT_LAST_NAME_PATH = "/client/Contact/last_name";

  const UPDATE_CALL_INTERVAL = 15000; // milliseconds

  let editTimeout = null;
  let editedRows = [];
  let tabChanges = [];
 
  useEffect(() => {
    const interval = setInterval(async () => {
      const relevantRecordNodes = [];
      gridApi.forEachNode((node) => {
        if (node.data.transitionStatus) {
          relevantRecordNodes.push(node);
        }
      });

      // nothing with a transition status? Then don't do anything
      if (relevantRecordNodes.length === 0) {
        return;
      }

      const responses = await api.getRecordUpdates(props.isAccount, relevantRecordNodes.map(node => node.data.record_id));

      let recordUpdates = []
      for (const response of responses) {
        recordUpdates = recordUpdates.concat(response.body.updates);
      }

      const changedRecordNodes = [];
      const dataToUpdate = [];
      for (const i in relevantRecordNodes) {
        const node = relevantRecordNodes[i];

        // note that the reference relies on the fact that order is maintained
        if (node.data.transitionStatus !== recordUpdates[i].transitionStatus) {
          node.data = recordUpdates[i];

          changedRecordNodes.push(node);
          dataToUpdate.push(node.data);
        }
      }

      gridApi.refreshCells({ force: true, rowNodes: relevantRecordNodes });
      gridApi.applyTransaction({ update: dataToUpdate });
    }, UPDATE_CALL_INTERVAL);

    return () => clearInterval(interval);
  }, [gridApi]);

  useEffect(() => {
    props.data.forEach(row => delete row.transitionStatus);

    document.getElementById('root').addEventListener('click', () => {
      setActiveDropdown(null);
    });
  }, [props.data]);

  useEffect(() => {
    if (contactUpdate === null) return;

    // Extend the picklist for autorized signer with all contact names if we are in contact mode.
    if (!props.isAccount) {
      const contactNames = props.data
        .reduce((acc, row) => {
          const name = `${row[CONTACT_FIRST_NAME_PATH] || ""} ${row[CONTACT_LAST_NAME_PATH] || ""}`.trim();
          if (name.length) { acc.push(name); }
          return acc;
        }, []);
        
      const authorizedSignerDef = props.modelDefs
        .getGroups('contacts')
        .reduce((acc, group) => {
          acc.push(...group.getDefs());
          return acc;
        }, [])
        .find(def => def.element_id === "CC0020");
      
      const uniqueContactNames = Array.from(new Set([...contactNames, ...authorizedSignerDef.getSelectableValues()]));
      uniqueContactNames.sort();

      authorizedSignerDef.values = uniqueContactNames;
      
      setContactUpdate(null);
    }
  }, [contactUpdate, props.data, props.isAccount, props.modelDefs]);

  // Deselect all rows on tab change
  useEffect(() => {
    setSelectedRows([]);
  }, [props.tab]);

  useEffect(() => {
    async function getURL() {
      try {
        const url = await getSetting('project.CAS Base URL');
        // creating object to make sure it's safe right off the bat.
        new URL(url);
        setCasBaseUrl(url);
      } catch (e) {
        console.error('Could not set CAS Base URL', e);
        // Note: This dummy location is set to allow the end user to keep using the app even if
        // the URL won't automatically click.
        setCasBaseUrl(`${window.location.origin}/errors/No_base_url/`);
      }
    }

    getURL();
  }, []);


  // useCallback may not strictly be needed.
  const renderLink = useCallback(
    (params) => {
      // Split the external ID into the appropriate parts
      const [bubble,teamGLCode, parsedId] = (params?.value || "").split('-');
      const href = new URL(parsedId, casBaseUrl);
      // Have to use rendertoStaticMarkup because AG Grid is being weird.
      return ReactDOMServer.renderToStaticMarkup(
        <a href={href}>{params?.value}</a>
      );
    },
    [casBaseUrl]
  );

  if (!casBaseUrl) {
    return <LoadingBox subMessage="Fetching settings..." />
  }

  let editButtonMarkup;
  if (props.canBulkEditRecords && props.flags.editRecords) {
    editButtonMarkup = (
      <TableButton
        icon="fa-edit"
        text={selectedRows.length > 1 ? `Bulk Edit Selected` : 'Bulk Edit'}
        onClick={() => setShowBulkEditModal(true)}/>
    );
  }

  let promoteButtonMarkup;
  if (props.canPromoteRecords && props.flags.promoteRecords) {
    promoteButtonMarkup = (
      <TableButton
        icon="fa-arrow-alt-circle-up"
        text={selectedRows.length > 1 ? `Promote Selected` : 'Promote'}
        onClick={() => setShowPromoteModal(true)}/>
    );
  }

  let demoteButtonMarkup;
  if (props.canDemoteRecords && props.flags.demoteRecords) {
    demoteButtonMarkup = (
      <TableButton
        icon="fa-arrow-alt-circle-down"
        text={selectedRows.length > 1 ? `Demote Selected` : 'Demote'}
        onClick={() => setShowDemoteModal(true)}/>
    );
  }

  let deleteButtonMarkup;
  if (props.canDeleteRecords && props.flags.deleteRecords) {
    deleteButtonMarkup = (
      <TableButton
        icon="fa-ban"
        text={selectedRows.length > 1 ? `Delete Selected` : 'Delete'}
        onClick={() => setShowDeleteModal(true)}/>
    );
  }

  let restoreButtonMarkup;
  if (props.canDeleteRecords && props.flags.deleteRecords) {
    restoreButtonMarkup = (
      <TableButton
        icon="fa-undo-alt"
        text="Restore"
        onClick={() => setShowRestoreModal(true)}/>
    );
  }

  let commitButtonMarkup;
  if (props.canCommitRecords && props.flags.commitRecords) {
    commitButtonMarkup = (
      <TableButton
        icon="fa-check-circle"
        text={selectedRows.length > 1 ? `Submit Selected to CAS` : 'Submit to CAS'}
        onClick={() => setShowCommitModal(true)}/>
    );
  }

  let assignCaaButtonMarkup;
  if (props.canAssignCaaNumber) {
    assignCaaButtonMarkup = (
      <TableButton
        icon="fa-hashtag"
        text={selectedRows.length > 1 ? `Assign CAA Numbers` : 'Assign CAA Number'}
        onClick={() => setShowAssignCaaNumberModal(true)}/>
    );
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
  }

  const onSelectionChanged = (event) => {
    setSelectedRows(gridApi.getSelectedNodes());
  };

  const onPageSizeChanged = (newPageSize) => {
    if (typeof newPageSize === 'number') {
      gridApi.paginationSetPageSize(newPageSize);
    }
    setPageSize(newPageSize);
  }

  const columnMarkup = props.modelDefs.getGroups(props.isAccount ? props.tab : 'contacts').map((group) => {
    const subColumns = group.getDefs().map((def) => {
      if ((!def.isAdvisorViewable(props.tab) && !props.isReviewer) || (!def.isReviewerViewable(props.tab) && props.isReviewer)) {
        return undefined;
      }
      function setEditability(params) {
        return (
          props.flags.editRecords &&
          // the "-ing" statuses where we can't edit during the transition
          !params.data.transitionStatus &&
          // conditionally editable
          ((props.canEditPromotedRecords && params.data.status === 'promoted') || params.data.status !== 'promoted') &&
          // always not editable
          params.data.status !== 'committed' &&
          !params.data.deleted &&
          def.getPath() !== CAA_NUMBER_PATH
        );
      }

      let editor = 'agTextCellEditor'; // acceptable for "Numeric" and "Text" input types (also as a default)
      let cellRenderer = 'defaultCellRenderer'; // Allow us to customize the renderer as needed

      const cellEditorParams = {
        setShowFlyOut: props.setShowFlyOut,
        attachments: props.attachments,
        showFilePickerModal: (options) => {
          setFilePickerModalOptions(options);
          setShowFilePickerModal(true);
        },
      };
      
      if (def.getType() === 'Enumerated List') {
        editor = 'agSelectCellEditor';
        cellEditorParams['values'] = def.getSelectableValues();
      }
      else if (def.getType() === 'Date') {
        editor = 'dateEditorComponent'
      }
      else if (def.getType() === 'PDF File') {
        editor = 'fileEditorComponent';
        cellRenderer = 'fileCellRenderer';
      } else if (def?.path?.includes('_external_id')) {
        // if it ends in _external_id it's going to CAS
        cellRenderer = renderLink;
      }


      return (
        <AgGridColumn
          headerName={def.getLabel()}
          field={def.getPath()}
          hide={!!hiddenColumnIds[def.getId()]}
          pinned={!!frozenColumnIds[def.getId()] ? 'left' : ''}
          cellEditor={editor}
          cellEditorParams={cellEditorParams}
          editable={setEditability}
          cellRenderer={cellRenderer}
          cellRendererParams={{
            setShowFlyOut: props.setShowFlyOut,
            attachments: props.attachments
          }}
          resizable
          headerTooltip={def.getTooltip() ? def.getTooltip() : undefined}
          sortable
          filter
          initialWidth={def.getLabel().length * 8 + 50}></AgGridColumn>
      );
    });

    return (
      <AgGridColumn headerName={group.name}>
        {subColumns}
      </AgGridColumn>
    );
  });

  async function updateRows(rows, transitionStatus, onValidationSuccess=async () => {}, onValidationError=async () => {}) {
    // This map is used to update the rows bu id from the response
    const rowMap = new Map();
    
    if (transitionStatus !== "deleting") {
      rows = rows.filter(row => row.data.status !== 'deleted');
    }

    if (!props.isAccount) {
      setContactUpdate(rows);
    } 

    for (const row of rows) {
      rowMap.set(row.data.record_id, row);
      
      delete row.data.validationErrors;
      row.data.transitionStatus = transitionStatus;
      
      // clear out newly-obsolete status values
      if (row.data.status === 'invalid') {
        delete row.data.status;
      }
      
      // clear out any "empty string" values to ensure proper validation
      Object.keys(row.data).forEach((key) => {
        if (row.data[key] === "") {
          delete row.data[key];
        }
      });
    }
    
    gridApi.refreshCells({ force: true, rowNodes: rows });
    gridApi.applyTransaction({ update: rows.map(row => row.data) });
    
    const responses = await api.updateRows(props.isAccount, rows.map(row => row.data));
   
    if (transitionStatus !== "deleting") {

      // Remove the transition status and assume validation success.
      for (const row of rows) {
        delete row.data.transitionStatus;
        row.data.invalid = false;
      }

      // For each row that failed validation set it invalid.
      for (const res of responses) {
        for (const validationEntry of res.body.validation) {
          const row = rowMap.get(validationEntry.record_id);
          row.data.invalid = true;
          row.data.validationErrors = validationEntry.errors;
        }
      }

      // Sort records for callbacks
      const [validationSuccessRows, validationErrorRows] = rows.reduce(([successes, errors], row) => {
        row.data.invalid ? errors.push(row) : successes.push(row);
        return [successes, errors];
      }, [[], []]);
      
      if (validationSuccessRows.length > 0) {
        await onValidationSuccess(validationSuccessRows);
      }
      
      if (validationErrorRows.length > 0) {
        await onValidationError(validationErrorRows);
      }
      
      // Update the rows with any changes from the callbacks.
      // we are more confident the responses will be the same as we've tossed these records up just a moment ago
      const dataToUpdate = rows.map(row => row.data);
      await api.updateRows(props.isAccount, dataToUpdate);
      
      gridApi.refreshCells({ force: true, rowNodes: rows });
      gridApi.applyTransaction({ update: dataToUpdate });
    }
  }

  async function editRows(rows, tabUpdates) {
    // This is a heavy O(n) solution... it's best to do this on the backend instead
    const clearRowDupData = [];
    gridApi.forEachNode((rowNode) => {
      if (rowNode.data.frontDuplicate) {
        delete rowNode.data.frontDuplicate;
        clearRowDupData.push(rowNode);
      }
    });
    gridApi.refreshCells({ force: true, rowNodes: clearRowDupData });
    gridApi.applyTransaction({ update: clearRowDupData.map(row => row.data) });

    const accountDupMap = {};
    const clientDupMap = {};
    gridApi.forEachNode((rowNode) => {
      if (
        rowNode.data['/agreement/Client Agreement & Billing Details/custodian'] &&
        rowNode.data['/agreement/Account/account_number'] &&
        !rowNode.data.deleted
      ) {
        const key = `${rowNode.data['/agreement/Client Agreement & Billing Details/custodian']}${rowNode.data['/agreement/Account/account_number']}`
        if (accountDupMap[key]) {
          accountDupMap[key].push(rowNode);
        }
        else {
          accountDupMap[key] = [rowNode];
        }
      } else if (rowNode.data['/client/Contact/tax_id'] && !rowNode.data.deleted) {
        const key = rowNode.data['/client/Contact/tax_id'];
        if (clientDupMap[key]) {
          clientDupMap[key].push(rowNode);
        }
        else {
          clientDupMap[key] = [rowNode];
        }
      }
    });

    let newRowData = [];
    for (const value of Object.values(accountDupMap)) {
      if (value.length > 1) {
        value.forEach(val => val.data.frontDuplicate = 'true');
        newRowData = newRowData.concat(value);
      }
    }

    for (const value of Object.values(clientDupMap)) {
      if (value.length > 1) {
        value.forEach(val => val.data.frontDuplicate = 'true');
        newRowData = newRowData.concat(value);
      }
    }
    
    gridApi.refreshCells({ force: true, rowNodes: newRowData });
    gridApi.applyTransaction({ update: newRowData.map(row => row.data) });
    
    await updateRows(rows, 'updating');
    
    // we also have to see what we have to "remove" from the table if there is a tab change
    let removeRowData = [];
    if (props.isAccount) {
      removeRowData = tabUpdates.map((row) => row.node);
      props.updateTabs(tabUpdates);
    }

    gridApi.applyTransaction({ update: newRowData.map(row => row.data), remove: removeRowData.map(row => row.data) });
  }

  function listValidationErrors(rows, maxToDisplay) {
    let count = 0;
    let rowNum = 0;
    let result = '';

    const numErrors = rows
      .map(row => Object.values(row.data.validationErrors))
      .reduce((acc, errorList) => acc + errorList.length, 0);

    listErrors:
    for (const row of rows) {
      rowNum += 1;
      result += `\nRow ${rowNum}`

      for (const [field, error] of Object.entries(row.data.validationErrors)) {
        const label = pathNameMap.get(field);
        result += `\n\t${label} ${error}`

        count += 1;
        if (count === maxToDisplay) {
          break listErrors;
        }
      }
    }

    let numRemainingMsg = '';
    if (numErrors > maxToDisplay) {
      numRemainingMsg = `(${numErrors-maxToDisplay} more errors...)`;
    }

    result += `\n\n${numRemainingMsg}`

    return result;
  }

  async function assignCaaNumbers(rows) {
    for (const row of rows) {
      row.data.transitionStatus = 'updating';
    }

    gridApi.refreshCells({ force: true, rowNodes: rows });
    gridApi.applyTransaction({ update: rows.map(row => row.data) });

    const rowsNeedingCaaNumbers = rows.filter(row => row.data['/agreement/Client Agreement & Billing Details/caa_number'] === undefined);
    const response = await api.assignCaaNumbers(rowsNeedingCaaNumbers.map(row => row.data.record_id));

    if (response.ok) {
      const updatedAccounts = response.body.accounts;
      for (const updatedAccount of updatedAccounts) {
        const target = rowsNeedingCaaNumbers.find(row => row.data.record_id === updatedAccount.record_id);
        target.data[CAA_NUMBER_PATH] = updatedAccount[CAA_NUMBER_PATH]
      }
    } else {
      setAlertText(`Failed to assign CAA number to ${rowsNeedingCaaNumbers.length} row${rowsNeedingCaaNumbers.length > 1 ? 's': ''}.`);
      setShowAlertModal(true);
    }

    for (const row of rows) {
      delete row.data.transitionStatus;
    }

    gridApi.refreshCells({ force: true, rowNodes: rows });
    gridApi.applyTransaction({ update: rows.map(row => row.data) });
  }

  async function promoteRows(rows) {
    await updateRows(
      rows,
      'promoting',
      async (rows) => rows.forEach(row => row.data.status = "promoted"),
      async (rows) => {
        if (rows.length > 0) {
          let alertText = `Failed to promote ${rows.length} row${rows.length > 1 ? 's': ''}.` +
            `\n${listValidationErrors(rows, 10)}`;
  
          setAlertText(alertText);
          setShowAlertModal(true);
        }
      }
    );
  }

  async function demoteRows(rows, demoteReason) {
    if (!props.isAccount) {
      setContactUpdate(rows);
    }

    for (const row of rows) {
      row.data.transitionStatus = 'demoting';
    }
    
    gridApi.refreshCells({ force: true, rowNodes: rows });
    gridApi.applyTransaction({ update: rows.map(row => row.data) });
    
    await api.demoteRows(props.isAccount, rows.map(row => [row.data, demoteReason]));

    // Remove the transition status and assume validation success.
    const dataToUpdate = [];
    for (const row of rows) {
      delete row.data.transitionStatus;
      row.data.status = 'demoted';
      row.data.hasFeedback = true;
      dataToUpdate.push(row.data);
    }
    
    gridApi.refreshCells({ force: true, rowNodes: rows });
    gridApi.applyTransaction({ update: dataToUpdate });
  }

  async function commitRows(rows) {
    if (props.isAccount) {
      await assignCaaNumbers(rows);
    }

    // This map is used to update the rows bu id from the response
    const rowMap = new Map();
    
    for (const row of rows) {
      rowMap.set(row.data.record_id, row);
      
      delete row.data.validationErrors;
      row.data.transitionStatus = 'committing';
      
      // clear out newly-obsolete status values
      if (row.data.status === 'invalid' || row.data.status === 'deleted') {
        delete row.data.status;
      }
      
      // clear out any "empty string" values to ensure proper validation
      Object.keys(row.data).forEach((key) => {
        if (row.data[key] === "") {
          delete row.data[key];
        }
      });
    }
    
    gridApi.refreshCells({ force: true, rowNodes: rows });
    gridApi.applyTransaction({ update: rows.map(row => row.data)});
    
    const responses = await api.submitRows(props.isAccount, rows.map(row => row.data));

    // Remove the transition status and assume validation success.
    for (const row of rows) {
      row.data.invalid = false;
    }

    // For each row that failed validation set it invalid.
    for (const res of responses) {
      for (const validationEntry of res.body.validation) {
        const row = rowMap.get(validationEntry.record_id);
        delete row.data.transitionStatus;
        row.data.invalid = true;
        row.data.validationErrors = validationEntry.errors;
      }
    }

    // Sort records for callbacks
    const [validationSuccessRows, validationErrorRows] = rows.reduce(([successes, errors], row) => {
      row.data.invalid ? errors.push(row) : successes.push(row);
      return [successes, errors];
    }, [[], []]);

    // Our alert text is going to be a bit different than normal. We want to display validation errors- but we also want
    // to let the user know that success might take a bit of time
    let alertText = `${validationSuccessRows.length} of ${rows.length} rows passed validation.`;
    alertText += `\n\nNote that submitting many rows can take some time. Rows will be refreshed every ${UPDATE_CALL_INTERVAL / 1000} seconds to display the latest status.`;
    if (validationErrorRows.length > 0) {
      alertText += `\n\nFailed to complete ${validationErrorRows.length} row${validationErrorRows.length > 1 ? 's': ''}.` +
        `\n${listValidationErrors(validationErrorRows, 10)}`;
    }
    setAlertText(alertText);
    setShowAlertModal(true);
    
    // Update the rows with any changes from the callbacks.
    // we are more confident the responses will be the same as we've tossed these records up just a moment ago
    const dataToUpdate = rows.map(row => row.data);
    
    gridApi.refreshCells({ force: true, rowNodes: rows });
    gridApi.applyTransaction({ update: dataToUpdate });
  }

  async function deleteRows(rows) {
    await updateRows(
      rows,
      'deleting',
      async (rows) => rows.forEach(row => row.data.deleted = true),
      async (rows) => rows.forEach(row => row.data.deleted = true)
    );

    gridApi.refreshCells({ force: true, rowNodes: rows });
    gridApi.applyTransaction({ remove: rows.map(row => row.data) });
  }

  async function restoreRows(rows) {
    await updateRows(
      rows,
      'restoring',
      async (rows) => rows.forEach(row => delete row.data.deleted),
      async (rows) => rows.forEach(row => delete row.data.deleted));
  }

  // Adding records
  async function createNewRow() {
    props.addRecord();
  }

  let addRecordButtonMarkup;
  if (props.canAddRecords) {
    addRecordButtonMarkup = <TableButton icon="fa-plus" text="Add Record" onClick={() => { createNewRow() }}/>
  }

  // determine "selectability" of rows (used as a prop below)
  function isRowSelectable(rowNode) {
    return (
      // don't allow transitioning rows to be selected
      (!rowNode.data.transitionStatus) &&
      // depend on props to see if you can select promoted rows
      ((props.canEditPromotedRecords && rowNode.data.status === 'promoted') || rowNode.data.status !== 'promoted') &&
      // don't allow selecting "sent to CAS" rows
      (rowNode.data.status !== 'committed')
    );
  }

  return (
    <>
      {/* Modals */}
      <RestoreModal
        show={showRestoreModal}
        recordCount={selectedRows.filter(selectedRow => !!selectedRow.data.deleted).length}
        onRestore={() => {
          const deletedRows = selectedRows.filter(selectedRow => !!selectedRow.data.deleted);
          restoreRows(deletedRows);
          setShowRestoreModal(false);
        }}
        onCancel={() => { setShowRestoreModal(false) }}/>
      <DeleteModal
        show={showDeleteModal}
        recordCount={selectedRows.filter(selectedRow => !selectedRow.data.deleted).length}
        onDelete={() => {
          const notDeletedRows = selectedRows.filter(selectedRow => !selectedRow.data.deleted);
          deleteRows(notDeletedRows);
          setShowDeleteModal(false);
        }}
        onCancel={() => { setShowDeleteModal(false) }}/>
      <AssignCaaNumberModal
        show={showAssignCaaNumberModal}
        recordCount={selectedRows.length}
        onContinue={() => {
          if (editTimeout !== null) {
            clearTimeout(editTimeout);

            // Don't await this, otherwise we block the render
            Promise.resolve()
              .then(async () => {
                await editRows(editedRows);
                editedRows = [];
              })
              .then(() => assignCaaNumbers(selectedRows))
              .catch(err => console.error(err));
          } else {
            assignCaaNumbers(selectedRows);
          }

          setShowAssignCaaNumberModal(false);
        }}
          onCancel={() => setShowAssignCaaNumberModal(false)} />
      <PromoteModal
        show={showPromoteModal}
        recordCount={selectedRows.length}
        onPromote={() => {
          const promotableRows = selectedRows.filter(row => !row.data.deleted && !row.data.frontDuplicate);
          promoteRows(promotableRows);
          setShowPromoteModal(false);
        }}
        onCancel={() => { setShowPromoteModal(false) }} />
      <DemoteModal
        show={showDemoteModal}
        recordCount={selectedRows.length}
        onDemote={(demoteReason) => {
          demoteRows(selectedRows, demoteReason);
          setShowDemoteModal(false);
        }}
        onCancel={() => { setShowDemoteModal(false) }} />
      <ReviewerFeedbackModal
        show={showFeedbackModal}
        recordId={feedbackToView}
        onClose={() => { setShowFeedbackModal(false) }}/>
      <FilePickerModal
         show={showFilePickerModal}
         files={props.attachments}
         onUpload={(newAttachment) => { 
           props.attachments = [newAttachment, ...props.attachments]; 
         }}
         onSelectFile={(file) => {
           filePickerModalOptions?.onSelectFile(file);
           setShowFilePickerModal(false);
         }}
         onClear={() => {
           filePickerModalOptions?.onClear();
           setShowFilePickerModal(false);
             }}
         onCancel={() => {
           filePickerModalOptions?.onCancel();
           setShowFilePickerModal(false);
         }}/>
      <AlertModal
        show={showAlertModal}
        text={alertText}
        onClose={() => { setShowAlertModal(false) }}/>
      <CommitModal
        show={showCommitModal}
        recordCount={selectedRows.length}
        isAccount={props.isAccount}
        onCommit={() => {
          commitRows(selectedRows);
          setShowCommitModal(false);
        }}
        onCancel={() => { setShowCommitModal(false) }} />
      <BulkEditModal
        show={showBulkEditModal}
        allColumns={allColumns}
        recordCount={selectedRows.length}
        onSave={(columnEdits) => {
          // pass updated columns for selected rows
          for (const row of selectedRows) {
            for (const edit of columnEdits) {
              if (edit.changed) {
                row.data[edit.column] = edit.value;
              }
            }
          }

          // then make the edit rows call
          editRows(selectedRows);

          // hide the modal
          setShowBulkEditModal(false);
        }}
        onCancel={() => { setShowBulkEditModal(false) }} />

      <div id="editor-controls" className="pagination">
        {/* Selection Actions */}
        <div id="selection-controls">
          {selectedRows.length > 0 ? <span id="selected-row-count-display">{selectedRows.length} { selectedRows.length > 1 ? 'rows' : 'row' } selected</span> : undefined}
          {selectedRows.length > 0 ? deleteButtonMarkup : undefined}
          {selectedRows.filter(selectedRow => !!selectedRow.data.deleted).length > 0 ? restoreButtonMarkup : undefined}
          {selectedRows.length > 0 ? editButtonMarkup : undefined}
          {selectedRows.length > 0 ? demoteButtonMarkup : undefined}
          {selectedRows.length > 0 ? promoteButtonMarkup : undefined}
          {selectedRows.length > 0 ? commitButtonMarkup : undefined}
          {selectedRows.length > 0 ? assignCaaButtonMarkup : undefined}
        </div>

        {/* "Always Available" Actions */}
        <div id="always-available-controls">
          {addRecordButtonMarkup}
          <HideColumnsButton
            active={activeDropdown === 'hide-columns'}
            setActive={() => activeDropdown === 'hide-columns' ? setActiveDropdown(null) : setActiveDropdown('hide-columns')}
            allColumns={allColumns}
            hiddenColumnIds={hiddenColumnIds}
            setHiddenColumnIds={setHiddenColumnIds} />
          <FreezeColumnsButton
            active={activeDropdown === 'freeze-columns'}
            setActive={() => activeDropdown === 'freeze-columns' ? setActiveDropdown(null) : setActiveDropdown('freeze-columns')}
            allColumns={allColumns}
            frozenColumnIds={frozenColumnIds}
            setFrozenColumnIds={setFrozenColumnIds} />
          <SelectPageSize
            active={activeDropdown === 'page-size'}
            setActive={() => activeDropdown === 'page-size' ? setActiveDropdown(null) : setActiveDropdown('page-size')}
            pageSize={pageSize}
            setPageSize={onPageSizeChanged} />
        </div>
      </div>

      <div id="editor-table-container" className="ag-theme-alpine">
        <AgGridReact
          onGridReady={onGridReady}
          rowData={Array.isArray(props.data) ? props.data : []}
          defaultColDef={{
            filterParams: {
              suppressAndOrCondition: true,
            }
          }}
          pagination
          paginationPageSize={pageSize}
          rowSelection="multiple"
          suppressRowClickSelection
          onSelectionChanged={onSelectionChanged}
          rowClassRules={{
            'committed-row': function(params) { return !params.data.transitionStatus && !params.data.deleted && params.data.status === 'committed' },
            'promoted-row': function(params) { return !params.data.transitionStatus && !params.data.deleted && params.data.status === 'promoted' },
            'demoted-row': function(params) { return !params.data.transitionStatus && !params.data.deleted && params.data.status === 'demoted' },
            'deleted-row': function(params) { return params.data.deleted },
            'updating-row': function(params) { return !!params.data.transitionStatus },
          }}
          isRowSelectable={isRowSelectable}
          enterMovesDownAfterEdit
          frameworkComponents={{
            'dateEditorComponent': DatePickerCell,
            'fileEditorComponent': FilePickerModalEditor,
            'statusCellRenderer': StatusCellRenderer,
            'fileCellRenderer': FileUploadRenderer,
            'defaultCellRenderer': DefaultCellRenderer,
            'feedbackCellRenderer': (props) => {
              return FeedbackCellRenderer({
                displayModal: (recordId) => {
                  setFeedbackToView(recordId);
                  setShowFeedbackModal(true);
                },
                props
              });
            },
          }}
          onCellValueChanged={(params) => {
            const index = editedRows.findIndex(editedRow => editedRow.id === params.node.id);
            if (index >= 0) {
              // we found the same row- let's replace
              editedRows = editedRows.map((editedRow, i) => index === i ? params.node : editedRow);
            }
            else {
              // didn't find it- append it
              editedRows = [...editedRows, params.node];
            }

            // if the edited column was wealth management service type, we'll need to see if we need to update tabs
            if (props.isAccount && params.column.colId === '/agreement/Client Agreement & Billing Details/wealth_management_service_type_(previously_"advisory")') {
              tabChanges.push({ recordId: params.data.record_id, node: params.node, fromTab: params.oldValue, toTab: params.newValue });
            }
          }}
          tooltipShowDelay={0}
          onCellEditingStarted={() => {
            // if we've started editing we don't every want to timeout and save as it might
            // disturb the edit
            if (editTimeout !== null) {
              clearTimeout(editTimeout);
              editTimeout = null;
            }
          }}
          onCellEditingStopped={(params) => {
            if (props.setShowFlyOut) props.setShowFlyOut(false);

            // if we've stopped editing we'll want to potentially update after a delay. This allows
            // a user to select another cell to edit in the meantime without an update bothering
            // them
            if (editTimeout === null) {
              editTimeout = setTimeout(() => {
                editRows(editedRows, tabChanges);
                editedRows = [];
                tabChanges = [];
              }, 3000);
            }
          }}>
          <AgGridColumn
            checkboxSelection
            headerCheckboxSelection
            headerCheckboxSelectionFilteredOnly
            lockPosition
            pinned="left"
            initialWidth={50}>
          </AgGridColumn>
          <AgGridColumn
            headerName="Status"
            field="status"
            cellRenderer="statusCellRenderer"
            sortable
            filter
            filterParams={{
              filterOptions: ['contains', 'notContains'],
            }}
            filterValueGetter={(params) => {
              let filterValue;

              if (params.data.deleted) {
                filterValue = 'deleted';
              } else {
                const is_invalid = params.data.invalid === 'true' || params.data.invalid === true;
                filterValue = params.data.status + (is_invalid ? ' invalid': '')
              }

              return filterValue;
            }}
            pinned="left"
            initialWidth={115}>
          </AgGridColumn>
          <AgGridColumn
            headerName="Feedback"
            cellRenderer="feedbackCellRenderer"
            pinned="left"
            initialWidth={100}>
          </AgGridColumn>
          {columnMarkup}
        </AgGridReact>
      </div>
    </>
  );
}
