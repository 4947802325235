const ID = {
  ACCOUNT_MANAGEMENT_TYPE: "ACA013",
  ADVISORY_INCLUDES_FINANCIAL_PLANNING: "AFF001",
  ASSET_MANAGER: "AFA001",
  BILLING_GROUP: "AFR004",
  BROKERAGE_SERVICE_TYPE: "ACA004",
  CAA_NUMBER: "ACA001",
  COMMISSIONABLE_ACCOUNT_STATUS: "AAC012",
  ENVESTNET_PLATFORM_USED: "AFA002",
  FEE_PAYMENT_TYPE: "AFR002",
  FEE_SCHEDULE: "ACA019",
  CUSTODY_FEE_TYPE: "ACA012",
  FINANCIAL_ADVISOR_NAME: "AW0001",
  FINANCIAL_ADVISOR_OWNER_COMMISSIONABLE: "AAC005",
  FIXED_INCOME: "AI0004",
  REVENUE_SHARE_SCHEDULE: "AFR001",
  HAS_SOLICITOR_AGREEMENT: "AFA006",
  INVESTMENT_POLICY_STATEMENT: "ACA006",
  INVESTMENT_RESTRICTIONS: "ACA007",
  INVOICE_GROUP: "AFR003",
  IS_THIS_A_RETIREMENT_ACCOUNT: "ACA018",
  IS_THIS_A_ROBO_ACCT: "ACA016",
  IS_THIS_ACCOUNT_A_ROLLOVER: "ACA015",
  IS_THIS_ACCOUNT_ERISA_AND_HELD_AWAY: "ACA002",
  NEW_BUSINESS_OR_COBD: "AAC011",
  OWNER_ID: "AW0001",
  PART_OF_ADVISORY_AGREEMENT: "AAC013",
  REGISTERED_OWNER_IS: "AFP021",
  TYPE_OF_DISCRETION: "ACA005",
  WEALTH_MANAGEMENT_SERVICE_TYPE: "ACA003",
  WILL_THIS_ACCT_HOLD_ALTERNATIVE_INVESTMENTS: "ACA017",

  ANNUAL_INCOME: "CC0013",
  ENTITY_TYPE: "CC0022",
  ESTIMATED_NET_WORTH: "CC0014",
  EXCLUDED_FROM_DUE_DILIGENCE: "CC0024",
  FEDERAL_TAX_BRACKET: "CC0016",
  GENERAL_INVESTMENT_KNOWLEDGE: "CC0018",
  GOVERNMENT_ID_NUMBER: "CC0010",
  GOVERNMENT_ID_TYPE: "CC0009",
  HOME_CITY: "CC0028",
  HOME_PHONE_NUMBER: "CC0039",
  HOME_STATE: "CC0029",
  HOME_STREET: "CC0026",
  HOME_STREET_LINE_2: "CC0027",
  HOME_ZIP: "CC0030",
  INVESTMENT_OBJECTIVES: "CC0019",
  JURISDICTION_OF_FORMATION: "CC0021",
  LIQUID_ASSETS: "CC0015",
  PERSONAL_EMAIL: "CC0041",
  WHAT_CAPACITY_IS_SIGNER: "CC0023",
};

const PROJECT = [
  {
    type: 'input',
    displayName: 'Name',
    path: 'Name',
    default: ''
  },
  {
    type: 'value',
    displayName: 'Status',
    path: 'Status',
    default: 'Active'
  },
  {
    type: 'value',
    displayName: 'Creation Date',
    path: 'Creation Date',
    default: '---'
  },
  {
    type: 'value',
    displayName: 'Start Date',
    path: 'Start Date',
    default: '---'
  },
  {
    type: 'value',
    displayName: 'Complete Date',
    path: 'Complete Date',
    default: '---'
  },
  {
    type: 'value',
    displayName: "CAS url",
    path: 'CAS Base URL',
    default: '-??-'
  },
  {
    type: 'input',
    displayName: 'Team Name',
    path: 'Team Name',
    default: ''
  },
  {
    type: 'input',
    displayName: 'Team GL Code',
    path: 'Team GL Code',
    default: ''
  },
];

const PROJECT_DEFAULTS = [
  {
    type: 'dropdown',
    displayName: 'Wealth Management Service Type',
    id: ID.WEALTH_MANAGEMENT_SERVICE_TYPE,
    // default?
  },
  {
    type: 'dropdown',
    displayName: 'Does the advisor have discretion?',
    id: ID.TYPE_OF_DISCRETION,
    default:'No'
  },
  {
    type: 'dropdown',
    displayName: 'Fee Type',
    id: ID.CUSTODY_FEE_TYPE,
    // default?
  },
];

const GLOBAL_DEFAULTS = [
  {
    type: 'dropdown',
    displayName: 'Is this account ERISA and held away?',
    id: ID.IS_THIS_ACCOUNT_ERISA_AND_HELD_AWAY,
    default: 'No'
  },
  {
    type: 'dropdown',
    displayName: 'Wealth Management Service Type',
    id: ID.WEALTH_MANAGEMENT_SERVICE_TYPE,
    default: ''
  },
  {
    type: 'dropdown',
    displayName: 'Brokerage Service Type',
    id: ID.BROKERAGE_SERVICE_TYPE,
    default: 'Full Service'
  },
  {
    type: 'dropdown',
    displayName: 'Does the advisor have discretion?',
    id: ID.TYPE_OF_DISCRETION,
    default: 'No'
  },
  {
    type: 'dropdown',
    displayName: 'Investment Policy Statement',
    id: ID.INVESTMENT_POLICY_STATEMENT,
    default: 'No'
  },
  {
    type: 'dropdown',
    displayName: 'Investment Restrictions',
    id: ID.INVESTMENT_RESTRICTIONS,
    default: 'No'
  },
  {
    type: 'dropdown',
    displayName: 'New Business or COBD?',
    id: ID.NEW_BUSINESS_OR_COBD,
    default: 'COBD'
  },
  {
    type: 'dropdown',
    displayName: 'Commissionable Account Status',
    id: ID.COMMISSIONABLE_ACCOUNT_STATUS,
    default: 'Draft'
  },
  {
    type: 'dropdown',
    displayName: 'Part of Advisory Agreement',
    id: ID.PART_OF_ADVISORY_AGREEMENT,
    default: 'No'
  },
  {
    type: 'dropdown',
    displayName: 'Fee Payment Type ',
    id: ID.FEE_PAYMENT_TYPE,
    default: 'Deducted from Custodian Account'
  },
  {
    type: 'dropdown',
    displayName: 'Asset manager',
    id: ID.ASSET_MANAGER,
    default: 'Advisor On Account',
  },
  {
    type: 'dropdown',
    displayName: 'Account Management Type',
    id: ID.ACCOUNT_MANAGEMENT_TYPE,
    default: 'Rep as PM'
  },
  {
    type: 'dropdown',
    displayName: 'Is this account a Rollover? ',
    id: ID.IS_THIS_ACCOUNT_A_ROLLOVER,
    default: 'No'
  },
  {
    type: 'dropdown',
    displayName: 'Does this account use a Robo advisor platform?',
    id: ID.IS_THIS_A_ROBO_ACCT,
    default: 'No'
  },
  {
    type: 'dropdown',
    displayName: 'Will this acct hold Alternative Investments?',
    id: ID.WILL_THIS_ACCT_HOLD_ALTERNATIVE_INVESTMENTS,
    default: 'No'
  },
  {
    type: 'dropdown',
    displayName: 'Is the Envestnet Platform Used for Managing this Account?',
    id: ID.ENVESTNET_PLATFORM_USED,
    default: 'No'
  },
  {
    type: 'dropdown',
    displayName: 'Is there a Solicitor Fee associated with this client account?',
    id: ID.HAS_SOLICITOR_AGREEMENT,
    default: 'No'
  },
  {
    type: 'dropdown',
    displayName: 'Is this a Retirement Account?',
    id: ID.IS_THIS_A_RETIREMENT_ACCOUNT,
    default: 'No'
  },
  {
    type: 'dropdown',
    displayName: 'Contact Government ID Type',
    id: ID.GOVERNMENT_ID_TYPE,
    default: 'State Drivers License'
  },
];

const ELIGIBLE_FOR_BYPASS = [
  {
    displayName: "Government ID Type",
    id: ID.GOVERNMENT_ID_TYPE,
    default: 'FALSE',
  },
  {
    displayName: "Government ID #",
    id: ID.GOVERNMENT_ID_NUMBER,
    default: 'FALSE',
  },
  {
    displayName: "Home Address- Street",
    id: ID.HOME_STREET,
    default: 'FALSE',
  },
  {
    displayName: "Home Address- Street Line 2",
    id: ID.HOME_STREET_LINE_2,
    default: 'FALSE',
  },
  {
    displayName: "Home Address- City",
    id: ID.HOME_CITY,
    default: 'FALSE',
  },
  {
    displayName: "Home Address- State",
    id: ID.HOME_STATE,
    default: 'FALSE',
  },
  {
    displayName: "Home Address- Zip Code",
    id: ID.HOME_ZIP,
    default: 'FALSE',
  },
  {
    displayName: "Phone#",
    id: ID.HOME_PHONE_NUMBER,
    default: 'FALSE',
  },
  {
    displayName: "Email",
    id: ID.PERSONAL_EMAIL,
    default: 'FALSE',
  },
  {
    displayName: "Annual Income",
    id: ID.ANNUAL_INCOME,
    default: 'FALSE',
  },
  {
    displayName: "Estimated Net Worth",
    id: ID.ESTIMATED_NET_WORTH,
    default: 'FALSE',
  },
  {
    displayName: "Investable/Liquid Assets",
    id: ID.LIQUID_ASSETS,
    default: 'FALSE',
  },
  {
    displayName: "Investment Objectives",
    id: ID.INVESTMENT_OBJECTIVES,
    default: 'FALSE',
  },
  {
    displayName: "Federal Tax Bracket",
    id: ID.FEDERAL_TAX_BRACKET,
    default: 'FALSE',
  },
  {
    displayName: "General Investment Knowledge",
    id: ID.GENERAL_INVESTMENT_KNOWLEDGE,
    default: 'FALSE',
  },
  {
    displayName: "In what capacity is this an Authorized Signer for the Owner?",
    id: ID.WHAT_CAPACITY_IS_SIGNER,
    default: 'FALSE',
  },
  {
    displayName: "Jurisdiction of Formation",
    id: ID.JURISDICTION_OF_FORMATION,
    default: 'FALSE',
  },
  {
    displayName: "Legal Entity Type",
    id: ID.ENTITY_TYPE,
    default: 'FALSE',
  },
  {
    displayName: "Is Entity Excluded from Due Diligence?",
    id: ID.EXCLUDED_FROM_DUE_DILIGENCE,
    default: 'FALSE',
  },
  {
    displayName: "Agreement includes Financial Planning",
    id: ID.ADVISORY_INCLUDES_FINANCIAL_PLANNING,
    default: 'FALSE',
  },
  {
    displayName: "Rollover?",
    id: ID.IS_THIS_ACCOUNT_A_ROLLOVER,
    default: 'FALSE',
  },
  {
    displayName: "Is this a Robo account?",
    id: ID.IS_THIS_A_ROBO_ACCT,
    default: 'FALSE',
  },
  {
    displayName: "CAA#",
    id: ID.CAA_NUMBER,
    default: 'FALSE',
  },
  {
    displayName: "Registered Owner Type",
    id: ID.REGISTERED_OWNER_IS,
    default: 'FALSE',
  },
];

module.exports = {
  PROJECT,
  GLOBAL_DEFAULTS,
  PROJECT_DEFAULTS,
  ELIGIBLE_FOR_BYPASS
}
