import AimModal from "../../../../shared/AimModal/AimModal";
import LoadingBox from "../../../../shared/LoadingBox/LoadingBox";
import TableButton from "../../../../shared/TableButton/TableButton";

import "./clear_picklists_modal.scss";

export default function ClearPicklistsModal(props) {
  return (
    <AimModal show={props.show}>
      <div id="clear-picklists-modal-header">
        <span>Reset Picklist Values</span>
      </div>
      <div id="clear-picklists-modal-body">
        <p>You're about to reset the picklists values. This action cannot be undone. Are you sure you want to continue?</p>
      </div>
      <div id="clear-picklists-modal-footer">
        <TableButton icon="fa-check-circle" text="Regenerate Picklists" onClick={props.onCommit} />
        <TableButton icon="fa-times-circle" text="Cancel" onClick={props.onCancel} />
      </div>
      {props.isLoading && <LoadingBox subMessage="Regenerating picklists this will take a few minutes ..." />}
    </AimModal>
  )
}
