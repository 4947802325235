import React from "react";
import * as api from "../../../../modules/api";
import ClearPicklistsModal from "./ClearPicklistsModal/ClearPicklistsModal";
import ClearSessionModal from "./ClearSessionModal/ClearSessionModal";
import ClearTablesModal from "./ClearTablesModal/ClearTablesModal";
import TableButton from "../../../shared/TableButton/TableButton";
import "./reset_data_section.scss";

export default function ResetDataSection() {

    const [showClearTablesModal, setShowClearTablesModal] = React.useState(false);
    const [clearTablesProcessing, setClearTablesProcessing] = React.useState(false);
    const [showClearPicklistsModal, setShowClearPicklistsModal] = React.useState(false);
    const [clearPicklistsProcessing, setClearPicklistsProcessing] = React.useState(false);
    const [showClearSessionModal, setShowClearSessionModal] = React.useState(false);
    const [clearSessionProcessing, setClearSessionProcessing] = React.useState(false);

    const resetDataTables = async () => {
        try {
            console.warn("Resetting data tables ...");
            const res = (await api.resetData()).body.message;
            console.warn(res);
            return res;
        } catch (err) {
            console.error(err)
        }
    };

    const resetPicklists = async (resetType) => {
        try {
            console.warn("Resetting picklists ...");
            const res = (await api.getPickLists(resetType)).body;
            console.warn("Completed resetting picklists ...");
        } catch (err) {
            console.error(err)
        }
    };

    return (
        <>
            <hr />
            <div className="project-reset-section">
                <h5 className="settings-header">
                    {"Reset Project Values"}
                </h5>
                <div className="project-settings-footer">
                    <table><tr><td>
                        <TableButton
                            icon="fa-refresh"
                            text="Reset Data Tables"
                            onClick={() => setShowClearTablesModal(true)} />
                        <ClearTablesModal
                            show={showClearTablesModal}
                            isLoading={clearTablesProcessing}
                            onCommit={async () => {
                                setClearTablesProcessing(true);
                                await resetDataTables();
                                await resetPicklists("picklist");
                                setShowClearTablesModal(false);
                                setClearTablesProcessing(false);
                            }}
                            onCancel={() => { 
                                setShowClearTablesModal(false) 
                                setClearTablesProcessing(false);
                            }} />
                        </td><td>
                            <TableButton
                                icon="fa-refresh"
                                text="Reset CAS Picklists"
                                onClick={() => setShowClearPicklistsModal(true)} />
                            <ClearPicklistsModal
                                show={showClearPicklistsModal}
                                isLoading={clearPicklistsProcessing}
                                onCommit={async () => {
                                    setClearPicklistsProcessing(true);
                                    await resetPicklists("picklist");
                                    setShowClearPicklistsModal(false);
                                    setClearPicklistsProcessing(false);
                                }}
                                onCancel={() => { 
                                    setShowClearPicklistsModal(false) 
                                    setClearPicklistsProcessing(false);
                                }} />
                        </td><td>
                            <TableButton
                                icon="fa-refresh"
                                text="Reset CAS Session"
                                onClick={() => setShowClearSessionModal(true)} />
                            <ClearSessionModal
                                show={showClearSessionModal}
                                isLoading={clearSessionProcessing}
                                onCommit={async () => {
                                    setClearSessionProcessing(true);
                                    await resetPicklists("session");
                                    setShowClearSessionModal(false);
                                    setClearSessionProcessing(false);
                                }}
                                onCancel={() => { 
                                    setShowClearSessionModal(false) 
                                    setClearSessionProcessing(false);
                                }} />
                        </td></tr>
                    </table>
                </div>
            </div>
        </>
    )
}
