import AimModal from "../../../../shared/AimModal/AimModal";
import LoadingBox from "../../../../shared/LoadingBox/LoadingBox";
import TableButton from "../../../../shared/TableButton/TableButton";

import "./clear_session_modal.scss";

export default function ClearSessionModal(props) {
    return (
      <AimModal show={props.show}>
        <div id="clear-session-modal-header">
          <span>Reset CAS Session</span>
        </div>
        <div id="clear-session-modal-body">
          <p>You're about to reset the CAS session. Are you sure you want to continue?</p>
        </div>
        <div id="clear-session-modal-footer">
          <TableButton icon="fa-check-circle" text="Reset CAS Session" onClick={props.onCommit}/>
          <TableButton icon="fa-times-circle" text="Cancel" onClick={props.onCancel}/>
        </div>
        {props.isLoading && <LoadingBox subMessage="Resetting CAS session and regenerating CAS picklists this will take a few minutes ..." />}
      </AimModal>
    )
}
