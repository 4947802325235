import React from "react";
import { matchSorter } from "match-sorter";

import AimModal from "../../AimModal/AimModal";
import FileDropZone from "../../../shared/FileDropZone/FileDropZone";
import TableButton from "../../TableButton/TableButton";

import "./file_picker_modal.scss";

export default function FilePickerModal({ show, onCancel, onSelectFile, onUpload, onClear, files}) {
  const uniqueFiles = (list) => Object.values(
    (list || []).reduce((acc, file) => {
      if (!acc[file.filename]) {
        acc[file.filename] = file;
      }

      return acc;
    }, {})
  );

  const [cursor, setCursor] = React.useState(-1);
  const [searchValue, setSearchValue] = React.useState('');
  const [fileList, setFileList] = React.useState(uniqueFiles(files));
  const [filteredFiles, setFilteredFiles] = React.useState(fileList);
  const [itemRefs] = React.useState(createItemRefs(fileList));

  const inputRef = React.useRef(null);
  const scrollDivRef = React.useRef(null);

  // Reset the search bar and cursor when the modal toggles
  React.useEffect(() => {
    setSearchValue('');
    const newMatches = matchSorter(Object.values(fileList), '', { keys: ['filename'] });
    setFilteredFiles(newMatches);

    scrollToTop();
    setCursor(0);

    if (show) {
      setTimeout(() => {
        inputRef.current.focus();
      });
    }
  }, [show, fileList]);

  // Recompute the lists if the master list of attachments changes
  React.useEffect(() => {
    setFileList(uniqueFiles(files));
    
    const newMatches = matchSorter(Object.values(fileList), searchValue, { keys: ['filename'] });
    setFilteredFiles(newMatches);

    scrollToTop();
    setCursor(0);
  }, [files, searchValue]);

  function createItemRefs(files) {
    return files.reduce((acc, value) => {
      acc[value.upload_id] = React.createRef();
      return acc;
    }, {});
  }

  function selectItem(item) {
    onSelectFile(item);
  }

  function scrollToItem(index) {
    if (index >= 0 && index < filteredFiles.length) {
      const cursorItem = filteredFiles[index];
      itemRefs[cursorItem.upload_id].current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }

  function scrollToTop() {
    scrollDivRef.current.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }

  function searchInputKeyHandler(e) {
    // Enter should select the highlighted element and close the modal
    if (e.code === 'Enter'){
      if (cursor >= 0 && cursor < filteredFiles.length) {
        selectItem(filteredFiles[cursor]);
        return;
      }
    }

    let newCursor = cursor;

    // Arrow up/down should select next/previous list element
    if (e.code === 'ArrowUp') {
      if (newCursor > 0) {
        newCursor -= 1;
      } else if (newCursor < 0) {
        newCursor = filteredFiles.length - 1;
      }

      scrollToItem(newCursor);
    }
    else if (e.code === 'ArrowDown') {
      if (newCursor < filteredFiles.length - 1) {
        newCursor += 1;
      }

      scrollToItem(newCursor);
    }
    // Anything else should just reset the selection (the user is probably refining the query)
    else {
      newCursor = 0;
    }

    setCursor(newCursor);
  }

  function filterFileList(searchValue) {
    setSearchValue(searchValue);

    //use the handy match-sorter to find closes values
    const newMatches = matchSorter(Object.values(fileList), searchValue, { keys: ['filename'] });

    setFilteredFiles(newMatches);
  }

  return (
    <AimModal show={show}>
      <div className="file-picker-header">
        <span>Upload New File</span>
      </div>
      <div className="upload-new-file-region">
        <FileDropZone
          onUpload={ (newFile) => {
            setFileList([...fileList, newFile]);
            onUpload(newFile);
          }}
          customClasses={["file-attach-drop-zone"]} />
      </div>

      <div className="uploaded-files-region">
        <div className="file-picker-header">
          <span>Select File</span>
        </div>
        <input
          ref={inputRef}
          value={searchValue}
          onKeyDown={searchInputKeyHandler}
          onChange={(e) => filterFileList(e.target.value)} />
        <div ref={scrollDivRef} className="uploaded-files-item-container">
          { filteredFiles.map((file, fileIndex) => {
              let className = "selectable uploaded-file-item";

              if (cursor === fileIndex) {
                className += ' selected';
              }

              return (
                <div
                  className={className}
                  ref={itemRefs[file.upload_id]}
                  onMouseOver={() => setCursor(-1)}
                  onClick={() => selectItem(file)}>
                  <span className="upload-date">{file.captured}</span>
                  <span>{file.filename}</span>
                </div>
              );
            }
          ) }
        </div>
      </div>
      <div id="alert-modal-footer">
        <TableButton text="Cancel" onClick={onCancel}/>
        <TableButton icon="fa-times-circle" text="Clear Value" onClick={onClear}/>
      </div>
    </AimModal>
  )
}