import React from "react";
import * as api from "../../../modules/api";
import * as extract from "../../../modules/extractFromModel";
import * as settings_list from "./SettingsList";
import { isValidUrl } from '../../../modules/util';

import SettingsInput from "./SettingsInput";
import SettingsTextArea from "./SettingsTextArea";
import SettingsDropdown from "./SettingsDropdown";
import BypassEligibilityEditor from "./BypassEligibilityEditor/BypassEligibilityEditor";
import LoadingBox from "../../shared/LoadingBox/LoadingBox";
import ResetDataSection from "./ResetDataSection/ResetDataSection";

import "./project_settings.scss";

export default function ProjectSettings() {
  const [rawSettingsData, setRawSettingsData] = React.useState(null);

  const [picklists, setPicklists] = React.useState(null);

  const [projectInfo, setProjectInfo] = React.useState(null);
  const [infoUpdate, setInfoUpdate] = React.useState(null);

  const [globalDefaults, setGlobalDefaults] = React.useState(null);
  const [globalDefaultsUpdate, setGlobalDefaultsUpdate] = React.useState(null);

  const [projectDefaults, setProjectDefaults] = React.useState(null);
  const [projectDefaultsUpdate, setProjectDefaultsUpdate] = React.useState(null);

  const [bypassFields, setBypassFields] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [savingSetting, setSavingSetting] = React.useState(false);
  const [resetButtonsVisible, setResetButtonsVisible] = React.useState(false);

  React.useEffect(() => {
    async function getSettings() {
      setLoading(true);

      let settings = [];
      let picklists;

      try {
        settings = (await api.getSettings()).body.settings;
        picklists = await extract.getPicklists();
        let visible = await resetVisible();
        setResetButtonsVisible(visible);
      } catch (fetchError) {
        console.error(fetchError);
        setLoading(false);
        return;
      }

      const rawSettings = {
        project: {},
        project_defaults: {},
        global_defaults: {},
        eligible_for_bypass: {}
      };

      settings.reduce((acc, setting) => {
        switch (setting.list_name) {
          case 'project':
            acc['project'][setting.item] = setting.value;
            break;
          case 'project_defaults':
            acc['project_defaults'][setting.item] = setting.value;
            break;
          case 'global_defaults':
            acc['global_defaults'][setting.item] = setting.value;
            break;
          case 'eligible_for_bypass':
            acc['eligible_for_bypass'][setting.item] = setting.value;
            break;
          default: break;
        }

        return acc;
      }, rawSettings);

      setPicklists(picklists);
      setRawSettingsData(rawSettings);
      setLoading(false);
    }

    getSettings();
  }, []);

  const saveSetting = async ({ list_name, item, value }) => {
    try {
      setSavingSetting(true);
      await api.updateSetting({ list_name, item, value });
    } catch (err)  {
      console.err(`failed to save setting ${err}`)
    }

    setSavingSetting(false);
  };

  const resetVisible = async () => {
    let visible = await api.resetButtonsVisible();
    return visible;
  };

  React.useEffect(() => {
    if (rawSettingsData == null) {
      return;
    }

    const mapValues = (raw, settings, settingCategory) => {
      settings.forEach(setting => {
        const settingKey = setting.path || setting.id;
        const savedValue = raw[settingKey];

        if (savedValue === undefined) {
          // If for some reason no entry exists for this setting, we attempt to create one with the default.
          if (setting.default === undefined) {
            console.warn(`No default found for ${setting.displayName}`);
            setting.value = '';
          } else {
            setting.value = setting.default;
            saveSetting({
              list_name: settingCategory,
              item: settingKey,
              value: setting.value
            });
          }
        } else {
          setting.value = raw[settingKey]
        }
      });
    };

    const attachUpdateCallback = (list, callback) => list.forEach(item => item.updateCallback = callback);

    const projectInfo = settings_list.PROJECT;
    const globalDefaults = settings_list.GLOBAL_DEFAULTS;
    const projectDefaults = settings_list.PROJECT_DEFAULTS;
    const bypassFields  = settings_list.ELIGIBLE_FOR_BYPASS;

    mapValues(rawSettingsData.project, projectInfo, 'project');
    mapValues(rawSettingsData.global_defaults, globalDefaults, 'global_defaults');
    mapValues(rawSettingsData.project_defaults, projectDefaults, 'project_defaults');
    mapValues(rawSettingsData.eligible_for_bypass, bypassFields, 'eligible_for_bypass');

    attachUpdateCallback(projectInfo, setInfoUpdate);
    attachUpdateCallback(globalDefaults, setGlobalDefaultsUpdate);
    attachUpdateCallback(projectDefaults, setProjectDefaultsUpdate);
    //No update callback for bypassFields: handled in subcomponent

    setProjectInfo(projectInfo);
    setProjectDefaults(projectDefaults);
    setGlobalDefaults(globalDefaults);
    setBypassFields(bypassFields);
  }, [rawSettingsData]);

  React.useEffect(() => {
    if (infoUpdate === null) return;

    const { rowIndex, value } = infoUpdate;
    const newProjectInfo = [...projectInfo];
    const row = newProjectInfo[rowIndex];
    row.value = value;

    saveSetting({ list_name: 'project', item: row.path, value: row.value });
    setProjectInfo(newProjectInfo);
    setInfoUpdate(null);
  }, [projectInfo, infoUpdate]);

  React.useEffect(() => {
    if (globalDefaultsUpdate === null) return;

    const { rowIndex, value } = globalDefaultsUpdate;
    const newGlobalDefaults = [...globalDefaults];
    const row = newGlobalDefaults[rowIndex];
    row.value = value;

    saveSetting({ list_name: 'global_defaults', item: row.id, value: row.value });
    setGlobalDefaults(newGlobalDefaults);
    setGlobalDefaultsUpdate(null);
  }, [globalDefaults, globalDefaultsUpdate]);

  React.useEffect(() => {
    if (projectDefaultsUpdate === null) return;

    const { rowIndex, value } = projectDefaultsUpdate;
    const newProjectdefaults = [...projectDefaults];
    const row = newProjectdefaults[rowIndex];
    row.value = value;

    saveSetting({ list_name: 'project_defaults', item: row.id, value: row.value });
    setProjectDefaults(newProjectdefaults);
    setProjectDefaultsUpdate(null);
  }, [projectDefaults, projectDefaultsUpdate]);
  
  const renderRow = (row, i) => {
    switch (row.type) {
      case 'value':

        return (
          <div key={`${row.value}-${i}`} className="project-settings-entry">
            <label>{row.displayName}</label>
            <div className="project-settings-value">
              {isValidUrl(row.value) ? <a href={row.value}>{row.value}</a> : row.value}
            </div>
          </div>
        );
      case 'input':
        return (
          <div key={`${row.value}-${i}`} className="project-settings-entry">
            <label>{row.displayName}</label>
            <SettingsInput
              value={row.value}
              rowIndex={i}
              updateCallback={row.updateCallback} />
          </div>
        );
      case 'textarea':
        return (
          <div key={`${row.value}-${i}`} className="project-settings-entry">
            <label>{row.displayName}</label>
            <SettingsTextArea
              value={row.value}
              rowIndex={i}
              updateCallback={row.updateCallback} />
          </div>
        );
      case 'dropdown':
        return (
          <div key={`${row.value}-${i}`} className="project-settings-entry">
            <label>{row.displayName}</label>
            <SettingsDropdown
              value={row.value}
              options={picklists[row.id]}
              picklistKey={row.id}
              rowIndex={i}
              updateCallback={row.updateCallback} />
          </div>
        );
      default:
        console.error(`Unexpected row type: ${row.type}`)
        return;
    }
  };

  // catch for loading modal
  if (loading) {
    return <LoadingBox subMessage="Please wait a moment while settings load." />;
  }

  console.log(`Show Reset Buttons: ${resetButtonsVisible}`); 
  
  return (
    <div id="project-settings-container">
      <div id="project-settings-region">
        <h4 className="settings-header">
          {"Project Settings"}
        </h4>
        <div id="settings-content">
          <div className="project-settings-section">
            {(projectInfo || []).map((row, i) => renderRow(row, i))}
          </div>
          <div className="project-settings-section">
            <h5 className="settings-header">
              {"Global Defaults"}
            </h5>
            {(globalDefaults || []).map((row, i) => renderRow(row, i))}
          </div>
          <div className="project-settings-section">
            <h5 className="settings-header">
              {"Project-level Defaults"}
            </h5>
            {(projectDefaults || []).map((row, i) => renderRow(row, i))}
          </div>
          <div className="project-settings-section">
            <h5 className="settings-header">
              {"Fields Eligible for Bypass"}
            </h5>
            <BypassEligibilityEditor bypassFields={bypassFields} />
          </div>
          {resetButtonsVisible && <ResetDataSection />}
        </div>
      </div>
    </div>
  );
}
