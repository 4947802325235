import * as api from "../modules/api";

// TODO: use element_id instead
const bulkEditableFields = [
  'ACA019', // Fee Schedule
  'AFR004', // Billing Group
  'ACA014', // Custodian
  'ACA012', // Fee Type
  'ACA013', // Account Management Type
  'ACA008', // Fee Basis
  'ACA005', // Does the advisor have discretion?
  'AFP021', // Registered Owner Is ?
  'AFR001', // Revenue Share Schedule Name
  'AFR005', // Revenue Share Schedule Date
  'AFA002', // Is the Envestnett Platform Used for Managing this Account?
  'AFA006', // Has Solicitor Agreement
  'ACA015', // Rollover?
  'ACA016', // Is this a Robo acct?
  'ACA017', // Will this acct hold Alternative Investments?
  'AAC005', // Advisor 2
  'AAC006', // Repcode
  'AAC011', // new or COBD
  'AAC012', // Commissionable Account Status
  'AAC013', // part of advisory agreement
  'ACA001', // CAA#
  'AFR002', // Fee Payment Type
  'AAC014', // Document Type Name
  'CC0031', // Contact - Home Country
  'CC0009', // Contact - Government ID Type
  'CC0010', // Contact - Government ID
];

class Model {
  constructor() {
    this.groups = [];
  }

  addGroup(newGroup) {
    this.groups.push(newGroup);
  }

  getGroups(tab) {
    switch (tab) {
      case 'contacts':
        // sort subgroups
        for (const group of this.groups) {
          group.getDefs().sort((a, b) => {
            if (a.contacts_column_order > b.contacts_column_order) {
              return 1;
            }
            else if (a.contacts_column_order < b.contacts_column_order) {
              return -1;
            }

            return 0;
          });
        }
        
        // sort the groups
        this.groups.sort((a, b) => {
          if (a.defs[0].contacts_column_order > b.defs[0].contacts_column_order) {
            return 1;
          }
          else if (a.defs[0].contacts_column_order < b.defs[0].contacts_column_order) {
            return -1;
          }

          return 0;
        });

        return this.groups;

      case 'advisory':
        // sort subgroups
        for (const group of this.groups) {
          group.getDefs().sort((a, b) => {
            if (a.advisory_columns_order > b.advisory_columns_order) {
              return 1;
            }
            else if (a.advisory_columns_order < b.advisory_columns_order) {
              return -1;
            }

            return 0;
          });
        }
        
        // sort the groups
        this.groups.sort((a, b) => {
          if (a.defs[0].advisory_columns_order > b.defs[0].advisory_columns_order) {
            return 1;
          }
          else if (a.defs[0].advisory_columns_order < b.defs[0].advisory_columns_order) {
            return -1;
          }

          return 0;
        });

        return this.groups;
      
      case 'brokerage':
        // sort subgroups
        for (const group of this.groups) {
          group.getDefs().sort((a, b) => {
            if (a.brokerage_columns_order > b.brokerage_columns_order) {
              return 1;
            }
            else if (a.brokerage_columns_order < b.brokerage_columns_order) {
              return -1;
            }

            return 0;
          });
        }
        
        // sort the groups
        this.groups.sort((a, b) => {
          if (a.defs[0].brokerage_columns_order > b.defs[0].brokerage_columns_order) {
            return 1;
          }
          else if (a.defs[0].brokerage_columns_order < b.defs[0].brokerage_columns_order) {
            return -1;
          }

          return 0;
        });

        return this.groups;

      case 'commissionable':
        // sort subgroups
        for (const group of this.groups) {
          group.getDefs().sort((a, b) => {
            if (a.commissionable_columns_order > b.commissionable_columns_order) {
              return 1;
            }
            else if (a.commissionable_columns_order < b.commissionable_columns_order) {
              return -1;
            }

            return 0;
          });
        }
        
        // sort the groups
        this.groups.sort((a, b) => {
          if (a.defs[0].commissionable_columns_order > b.defs[0].commissionable_columns_order) {
            return 1;
          }
          else if (a.defs[0].commissionable_columns_order < b.defs[0].commissionable_columns_order) {
            return -1;
          }

          return 0;
        });

        return this.groups;

      case 'profservices':
        // sort subgroups
        for (const group of this.groups) {
          group.getDefs().sort((a, b) => {
            if (a.professional_services_columns_order > b.professional_services_columns_order) {
              return 1;
            }
            else if (a.professional_services_columns_order < b.professional_services_columns_order) {
              return -1;
            }

            return 0;
          });
        }
        
        // sort the groups
        this.groups.sort((a, b) => {
          if (a.defs[0].professional_services_columns_order > b.defs[0].professional_services_columns_order) {
            return 1;
          }
          else if (a.defs[0].professional_services_columns_order < b.defs[0].professional_services_columns_order) {
            return -1;
          }

          return 0;
        });

        return this.groups;

      default:
        return this.groups;
    }
  }

  getAllDefs() {
    return this.groups.reduce((acc, group) => acc.concat(group.getDefs()), []);
  }
}

class ModelGroup {
  constructor(name) {
    this.name = name;
    this.defs = [];
  }

  addDef(newDef) {
    this.defs.push(newDef);
  }

  getDefs() {
    return this.defs;
  }
}

class AgreementModelDefinition {
  constructor(path, modelBlob, picklists) {
    this.path = path;

    // required
    this.data_type = modelBlob.data_type;
    this.element_id = modelBlob.element_id;
    this.label = modelBlob.element_label;
    this.field = modelBlob.salesforce_field;
    this.element_name = modelBlob.element_name;

    // boolean
    this.accepts_null = modelBlob.accepts_null === 'Yes';
    this.display_on_advisor_ui = modelBlob.display_on_advisor_ui === 'Yes';
    this.display_on_advisory_financial_account = modelBlob.display_on_advisory_financial_account === 'Yes';
    this.display_on_brokerage_financial_account = modelBlob.display_on_brokerage_financial_account === 'Yes';
    this.display_on_commissionable_financial_account = modelBlob.display_on_commissionable_financial_account === 'Yes';
    this.display_on_professional_services = modelBlob.display_on_professional_services === 'Yes';
    this.display_on_ui = modelBlob.display_on_ui === 'Yes';
    this.technically_required = !!modelBlob.technically_required;

    this.bulk_editable = bulkEditableFields.includes(modelBlob.element_id);

    // nullable
    this.length = modelBlob.length || null;
    this.ui_tooltip = modelBlob.ui_tooltip || null;
    this.advisory_columns_order = parseInt(modelBlob.advisory_columns_order) || 1000;
    this.brokerage_columns_order = parseInt(modelBlob.brokerage_columns_order) || 1000;
    this.commissionable_columns_order = parseInt(modelBlob.commissionable_columns_order) || 1000;
    this.professional_services_columns_order = parseInt(modelBlob.professional_services_columns_order) || 1000;

    // dropdown
    let values = null;
    const match = picklists[modelBlob.element_id];

    /*
      This is an awful shim to display only the values given by the model for Fee Payment Type

      HT-616
    */
    if (modelBlob.element_id === 'AFR002') {
      values = modelBlob.values ? [''].concat(modelBlob.values) : null;
    }
    else if (match) {
      values = [''].concat(match);
    }
    else {
      values = modelBlob.values ? [''].concat(modelBlob.values) : null;
    }
    this.values = values;
  }

  getPath() {
    return this.path;
  }

  getType() {
    return this.data_type;
  }

  getId() {
    return this.element_id;
  }

  getLabel() {
    return this.label;
  }

  getName() {
    return this.element_name;
  }

  getTooltip() {
    return this.ui_tooltip;
  }

  getSelectableValues() {
    return this.values;
  }

  isRequired() {
    return !this.accepts_null;
  }

  isBulkEditable() {
    return this.bulk_editable;
  }

  isAdvisorViewable(tab) {
    let advisorViewable = this.display_on_ui && this.display_on_advisor_ui;

    if (advisorViewable && tab) {
      switch (tab) {
        case 'advisory':
          advisorViewable = this.display_on_advisory_financial_account;
          break;
        
        case 'brokerage':
          advisorViewable = this.display_on_brokerage_financial_account;
          break;

        case 'commissionable':
          advisorViewable = this.display_on_commissionable_financial_account;
          break;

        case 'profservices':
          advisorViewable = this.display_on_professional_services;
          break;

        default:
          advisorViewable = false;
          break;
      }
    }

    return advisorViewable;
  }

  isReviewerViewable(tab) {
    let reviewerViewable = this.display_on_ui;

    if (tab) {
      switch (tab) {
        case 'advisory':
          reviewerViewable = this.display_on_advisory_financial_account;
          break;
        
        case 'brokerage':
          reviewerViewable = this.display_on_brokerage_financial_account;
          break;

        case 'commissionable':
          reviewerViewable = this.display_on_commissionable_financial_account;
          break;

        case 'profservices':
          reviewerViewable = this.display_on_professional_services;
          break;

        default:
          reviewerViewable = false;
          break;
      }
    }

    return reviewerViewable;
  }

  isAdminViewable() {
    return this.display_on_ui;
  }
}

class ClientModelDefinition {
  constructor(path, modelBlob, picklists) {
    this.path = path;

    // required
    this.data_type = modelBlob.data_type;
    this.element_id = modelBlob.element_id;
    this.label = modelBlob.element_label;
    this.element_name = modelBlob.element_name;
    this.type = modelBlob.type;
    this.field = modelBlob.salesforce_field;

    // boolean
    this.display_on_ui = modelBlob.display_on_ui === 'Yes';
    this.accepts_null = modelBlob.accepts_null === 'Yes';
    this.technically_required = !!modelBlob.technically_required;

    this.bulk_editable = bulkEditableFields.includes(modelBlob.element_id);

    // nullable
    this.comments = modelBlob.comments || null;
    this.examples = modelBlob.examples || null;
    this.length = modelBlob.length || null;
    this.validation_rules = modelBlob.validation_rules || null;
    this.ui_tooltip = modelBlob.ui_tooltip || null;
    this.contacts_column_order = modelBlob.contact_columns_order ? parseInt(modelBlob.contact_columns_order) : 1000;

    // dropdown
    let values = null;

    // TODO: this is a hack to get the same fields displaying for another column
    // const match = path === '/client/Ownership/csa_owner' ? picklists['AW0001'] : picklists[modelBlob.element_id];
    const match = picklists[modelBlob.element_id];
    if (match) {
      values = [''].concat(match);
    }
    else {
      values = modelBlob.values ? [''].concat(modelBlob.values) : null;
    }
    this.values = values;
  }

  getPath() {
    return this.path;
  }

  getType() {
    return this.data_type;
  }

  getId() {
    return this.element_id;
  }

  getLabel() {
    return this.label;
  }

  getName() {
    return this.element_name;
  }

  getTooltip() {
    return this.ui_tooltip;
  }

  getSelectableValues() {
    return this.values;
  }

  isRequired() {
    return !this.accepts_null;
  }

  isBulkEditable() {
    return this.bulk_editable;
  }

  isAdvisorViewable(tab) {
    return this.display_on_ui && this.contacts_column_order !== 1000;
  }

  isReviewerViewable(tab) {
    return this.display_on_ui && this.contacts_column_order !== 1000;
  }

  isAdminViewable() {
    return this.display_on_ui;
  }
}

async function loadModel(path, root) {
  const results = await fetch(path);
  const model = await results.json();

  return model.entities[root];
}

export async function getPicklists() {
  const response = (await api.getPickLists()).body;

  const picklists = response && response.records;

  // return an object with mappings between field and list of values
  if (Array.isArray(picklists)) {
    const results = picklists.reduce((acc, item) => {
      const picklistLabel = item.list_name.replace('/', '.');
      if (acc[picklistLabel]) {
        acc[picklistLabel].push(item.label);
      }
      else {
        acc[picklistLabel] = [item.label];
      }
  
      return acc;
    }, {});
  
    return results;
  }
  else {
    console.error('[MODEL]', 'Error retrieving picklists for model');
    return {};
  }
}

export async function getModelAgreements() {
  const agreementInfo = await loadModel('/model.json', 'agreement');
  
  const picklists = await getPicklists();

  // combine picklists- have the custom ones overwrite any pulled from CAS
  const combinedPicklists = {};
  for (const [key, value] of Object.entries(picklists)) {
    combinedPicklists[key] = value;
  }

  const model = new Model();
  for (const [groupName, groupDef] of Object.entries(agreementInfo)) {
    const group = new ModelGroup(groupName);
    for (const [colName, colDef] of Object.entries(groupDef)) {
      if (colDef.label !== null) {
        group.addDef(new AgreementModelDefinition(`/agreement/${groupName}/${colName}`, colDef, combinedPicklists));
      }
    }

    model.addGroup(group);
  }

  return model;
}

export async function getModelClients() {
  const clientInfo = await loadModel('/model.json', 'client');
  const picklists = await getPicklists();

  // combine picklists- have the custom ones overwrite any pulled from CAS
  const combinedPicklists = {};
  for (const [key, value] of Object.entries(picklists)) {
    combinedPicklists[key] = value;
  }

  const model = new Model();
  for (const [groupName, groupDef] of Object.entries(clientInfo)) {
    const group = new ModelGroup(groupName);
    for (const [colName, colDef] of Object.entries(groupDef)) {
      if (colDef.label !== null) {
        group.addDef(new ClientModelDefinition(`/client/${groupName}/${colName}`, colDef, combinedPicklists));
      }
    }

    model.addGroup(group);
  }

  return model;
}
